import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

function AddEvent() {
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [eventName, setEventName] = useState('');
    const [eventType, setEventType] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [eventDetails, setEventDetails] = useState('');
    const [eventPlace, setEventPlace] = useState('');
    const [eventAddress, setEventAddress] = useState('');
    const [ticketPage, setTicketPage] = useState('');
    const [priceType, setPriceType] = useState('');
    const [eventPrice, setEventPrice] = useState('');

    const priceTypeOptions = ["Free", "Performer", "Pay"];

    useEffect(() => {
        checkUserSignedIn();
    }, []);

    const checkUserSignedIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const allowedEmails = [
                'thydoraemon13@gmail.com',
                'ranmac3@hotmail.com',
                'goldrunnercode123@gmail.com',
                'hieuvpn@gmail.com'
            ];
            if (allowedEmails.includes(user.attributes.email)) {
                setIsUserSignedIn(true);
                console.log('Signed in');
            } else {
                setIsUserSignedIn(false);
                console.log('Not authorized to access this page');
            }
        } catch (error) {
            setIsUserSignedIn(false);
            console.log('Not signed in');
        }
    };

    const handleEventNameChange = (e) => {
        setEventName(e.target.value);
    };

    const handleEventTypeChange = (e) => {
        setEventType(e.target.value);
    };

    const handleEventDateChange = (e) => {
        setEventDate(e.target.value);
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    };

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    };

    const handleEventDetailsChange = (e) => {
        setEventDetails(e.target.value);
    };

    const handleEventPlaceChange = (e) => {
        setEventPlace(e.target.value);
    };

    const handleEventAddressChange = (e) => {
        setEventAddress(e.target.value);
    };

    const handleTicketPageChange = (e) => {
        setTicketPage(e.target.value);
    };

    const handlePriceTypeChange = (e) => {
        setPriceType(e.target.value);
    };

    const handleEventPriceChange = (e) => {
        setEventPrice(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
    
        var data = {
            event_name: eventName,
            event_type: eventType,
            event_date: eventDate,
            event_start_time: startTime,
            event_end_time: endTime,
            event_details: eventDetails,
            place: eventPlace,
            addresses: eventAddress,
            ticket_website: ticketPage,
            price_type: priceType,
            price: eventPrice
        };
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data),
            redirect: 'follow'
        };
    
        fetch("https://bjc3xuox61.execute-api.ca-central-1.amazonaws.com/Dev/", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    };


    if (!isUserSignedIn) {
        return <div>You are not authorized to access this page.</div>;
    }

    return (
        <div>
            <form className="add-event" onSubmit={handleSubmit}>
                <div>
                    <label>
                        Event Name:
                        <input type="text" value={eventName} onChange={handleEventNameChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Event Type:
                        <input type="text" value={eventType} onChange={handleEventTypeChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Date:
                        <input type="date" value={eventDate} onChange={handleEventDateChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Start Time:
                        <input type="time" value={startTime} onChange={handleStartTimeChange} step="300" />
                    </label>
                </div>
                <div>
                    <label>
                        End Time:
                        <input type="time" value={endTime} onChange={handleEndTimeChange} step="300" />
                    </label>
                </div>
                <div>
                    <label>
                        Details:
                        <textarea value={eventDetails} onChange={handleEventDetailsChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Place:
                        <input type="text" value={eventPlace} onChange={handleEventPlaceChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Address:
                        <input type="text" value={eventAddress} onChange={handleEventAddressChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Ticket Page:
                        <input type="text" value={ticketPage} onChange={handleTicketPageChange} />
                    </label>
                </div>
                <div>
                    <label>
                        Price Type:
                        <select value={priceType} onChange={handlePriceTypeChange}>
                            <option value=""> -- select an option -- </option>
                            {priceTypeOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>
                <div>
                    <label>
                        Price:
                        <input type="number" value={eventPrice} onChange={handleEventPriceChange} />
                    </label>
                </div>
                {(eventName && eventType && eventDate && startTime && endTime && eventDetails && eventPlace && ticketPage && priceType && eventPrice) && <button type="submit">Submit</button>}
            </form>
        </div>
    );
}

export default AddEvent;
