import React, { useEffect, useState } from 'react';
import './Desktop.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from "@emotion/react";
import {getTheme} from "./helper/color-pallete";
import { TextField, Typography, Box } from '@mui/material';
function Desktop() {
const [email, setEmail] = useState('');
const [name, setName] = useState('');
const [email2, setEmail2] = useState('');
const [message, setMessage] = useState('');

const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    const myHeaders = new Headers();
    const raw = JSON.stringify({
      "email": email2,
      "name": name,
      "message": message
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      mode: "no-cors"
    };

    fetch("https://admin.cluez.ca:3000/contact", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
      console.log({ name, email2, message });
};

  useEffect(() => {
    function checkWindowSize() {
      if (window.innerWidth <   600) {
        window.location.href = '/';
      }
    }
    checkWindowSize();
    window.addEventListener('resize', checkWindowSize);
    return () => window.removeEventListener('resize', checkWindowSize);
  }, []);
  
    // Method to handle input change
    const handleInputChange = (event) => {
        setEmail(event.target.value);
        // You can add additional logic here that runs when the input changes
      };
	const handleNavToContactClick = () => {
	  window.scrollTo(0, document.body.scrollHeight);
	}
	const handleQuestTicketClick = () => {
	  window.open('https://www.eventbrite.ca/e/hidden-gemz-quest-calgary-tickets-806005152107');
	}
    const handleButtonClick = () => {
		var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
		var requestOptions = {
		  method: 'GET',
		  redirect: 'follow',
		  headers: myHeaders
		};
		fetch('https://admin.cluez.ca:3000/bm/'+email, requestOptions)
			.then(response => response.text())
            .then(result => {} )
		  .catch(error => console.log('error', error)); 
    };
  return (
    <ThemeProvider theme={getTheme("LionstoothEnergy")}>
    <div className="home-page">
      <div className="contact-us-container">
        <img src="/images/contact-us.jpg" alt="Contact Us" className="contact-us-image" onClick={handleNavToContactClick} />
      </div>

      <div className="app-container">
        <img src="/images/app1.jpg" alt="App" className="app-image" />
        <div className="app-content">
          <b className="app-title">EXPERIENCE THE CITY LIKE A TRUE LOCAL</b>
          <p className="app-description">
            Engage in a thrilling city-wide game across Canada, earning points, rewards, and climbing the leaderboard as you uncover the most incredible spots.
            <br /><br />
            Hidden Gemz revolutionizes city exploration by putting the power in your hands. You decide which local favorites deserve recognition, from cozy cafes to captivating art galleries, and everything in between.
          </p>
          <p className="app-input-label">ENTER EMAIL TO BECOME THE NEXT HIDDEN GEMZ</p>
          <Stack spacing={2} direction="row">
          <input
            type="text"
            placeholder="Email"
            className="app-input"
            value={email}
            onChange={handleInputChange}
          />
             <Button
          variant="contained"
          color="button" // Change the color here
          onClick={handleButtonClick} // Method to run on click
        >
          ENTER
        </Button>
          </Stack>
        </div>
      </div>

      {/* Work in Progress Section */}
      <div className="work-in-progress-container">
        <div className="work-in-progress-content">
          <b className="app-title">WE ARE A WORK IN PROGRESS</b>
          <p className="app-description">
            We are only Mobile accessible! Scan the QR code to access Hidden Gemz on your mobile phone
            <br /><br />
            Collect points as you discover each hidden gem and climb your way up the citywide leaderboard. The more places you find, the higher you'll rise, so rally your friends and let the exploration begin!
          </p>
        </div>
        <img src="/assets/gemqr.png" alt="QR Code" className="gemqr-image" />
      </div>

      {/* Find the Best Places Section */}
      <div className="city-container">
        <img src="/images/city.jpg" alt="City" className="city-image" />
        <div className="city-content">
          <b className="app-title">FIND THE BEST</b>
          <b className="app-title">PLACES</b>
          <b className="app-title">IN THE CITY</b>
          <p className="app-description">
            With Hidden Gemz, you can explore a world of possibilities, from cozy cafes and thrilling arcade rooms to mouthwatering restaurants and captivating movie theaters.  
            <br /><br />
            Whether you're craving a delicious meal, seeking thrills at a mini-golf course, or simply in the mood for some quality entertainment, Hidden Gemz has something for everyone.
          </p>
        </div>
      </div>

      {/* Join Our Events Section */}
      <div className="join-events-container">
        <div className="join-events-content">
          <b className="app-title">JOIN OUR EVENTS</b>
          <p className="app-description">            
            Join our Quest to meet new people, forge friendships, and discover exciting destinations across the city together! With your ticket, you'll receive notifications about where we are meeting next, ensuring you never miss out on the fun. 
            <br /><br />
            Exciting news! Our Quest Mode is on its way to your city! Currently available in Calgary, AB.
          </p>
          <img src="/images/get-quest-ticket.jpg" alt="Get Quest Ticket" className="join-events-image" onClick={handleQuestTicketClick} />
        </div>
        <img src="/assets/iphone15.png" alt="Map" className="map-image" />
      </div>

      <b className="our-community-title">OUR COMMUNITY</b>
      {/* <a href="https://www.centrefornewcomers.ca/">
      <button className='invisible-button'></button>
      </a>
      <a href="https://innovatecalgary.com/">
      <button className='invisible-button'></button>
      </a>
      <a href="https://www.calgary.ca/home.html">
      <button className='invisible-button2'></button>
      </a>
      <a href="https://nrc.canada.ca/en">
      <button className='invisible-button3'></button>
      </a> */}
      
      <table className="table-container">
        <tr>
          <td>
            <img src="/images/community.jpg" alt="Community" className="community-image" />
          </td>
        </tr>
      </table>
      <div className="form-container">
      <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
        <b>
          Contact Us
        </b>
        <Typography variant="body2" gutterBottom>
          Have Something to Share?
        </Typography>
        <TextField
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          variant="outlined"
          value={email2}
          onChange={(e) => setEmail2(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Message"
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <Button variant="contained" color="button" type="submit" >
          Submit
        </Button>
      </Box>
    </div>
    </div>
    </ThemeProvider>
  );
}

export default Desktop;